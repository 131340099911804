import axios from "axios";

export const postTwitterVerify = async (
  oauth_token: string | null,
  oauth_verifier: string | null
) => {
  const resp = await axios.post(`https://verify.tanuki.id/twitter`, {
    oauth_token: oauth_token,
    oauth_verifier: oauth_verifier,
  });

  if (resp.data?.error) {
    throw resp.data?.message || "Error";
  }

  return resp;
};
