import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useWeb3Modal } from "@web3modal/react";
import { useAccount } from "wagmi";
import { maskAddress } from "../../utils/functions";

const Navbar = () => {
  const { open } = useWeb3Modal();
  const { address, isConnected } = useAccount();
  const location = useLocation();

  const isLinkSelected = (path: string) => {
    if (location?.pathname) {
      return location.pathname.includes(path);
    }
    return false;
  };

  return (
    <nav
      className="flex bg-no-repeat bg-cover w-full shadow p-4 justify-between"
      style={{ backgroundImage: "url(/bg_nav.png)" }}
    >
      <div className="flex items-center">
        <Link to="/">
          <img className="hidden md:block" src="/logo.png" alt="logo" />
        </Link>
        <Link className="ml-5" to="verify">
          <div
            className={`w-14 h-4 bg-no-repeat bg-contain bg-[url('../public/verify.png')] ${
              isLinkSelected("verify") ? "verify_selected" : ""
            } hover:bg-[url('../public/verify_selected.png')]`}
          />
        </Link>
        <Link className="ml-5" to="revoke">
          <div
            className={`w-14 h-4 bg-no-repeat bg-contain bg-[url('../public/revoke.png')] ${
              isLinkSelected("revoke") ? "revoke_selected" : ""
            } hover:bg-[url('../public/revoke_selected.png')]`}
          />
        </Link>
      </div>
      <div className="flex flex-initial items-center">
        {isConnected ? (
          <>
            <div className="hidden lg:flex items-center w-28 h-10 mx-5">
              <img
                className="w-full h-full"
                src="/connected_btn.png"
                alt="connect button"
              />
            </div>

            <div
              className="flex bg-no-repeat bg-contain w-36 justify-between h-10 mx-5"
              style={{ backgroundImage: "url(/address_bg.png)" }}
            >
              <h5 className="m-auto font-bold">
                {address ? maskAddress(address) : null}
              </h5>
            </div>
          </>
        ) : (
          <button className="w-28 h-10 mx-5" onClick={() => open()}>
            <img
              className="w-full h-full"
              src="/cnt_btn.png"
              alt="connect button"
            />
          </button>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
