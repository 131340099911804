const Footer = () => {
  return (
    <div className="flex h-36 w-screen items-end fixed bottom-0">
      <div className="hidden w-full h-24 bg-[#323331] md:block" />
      <div className="w-full h-36 md:max-w-[20rem] bg-[#003634] flex flex-col items-end justify-center">
        <h3 className="text-white text-right font-bold text-xl m-2">
          contact us to know more via:
        </h3>
        <div className="flex items-center gap-4 m-2 mr-6">
          <a
            target="_blank"
            href="https://discord.gg/cyberkongz"
            rel="noreferrer"
          >
            <img
              className="w-14 h-17 w-12 h-15"
              src="/discord.png"
              alt="connect button"
            />
          </a>
          <p className="text-white">or</p>
          <a
            target="_blank"
            href="https://twitter.com/kongztech"
            rel="noreferrer"
          >
            <img
              className="w-14 h-17 w-12 h-15"
              src="/twitter.png"
              alt="connect button"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
