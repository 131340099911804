import axios from "axios";

export const getGuildData = async (id: string) => {
  return axios.get(`https://external.kongztech.com/guild/${id}`);
};

export const postVerifyNFT = async (requestData: any) => {
  const resp = await axios.post(
    "https://external.kongztech.com/verify",
    requestData
  );

  if (resp.data?.error) {
    throw resp.data?.message || "Error";
  }

  return resp;
};
