import { useState } from "react";

type OverlayType = {
  state?: "loading" | "success" | "fail";
  message?: string;
};

type OverlayReturn = [
  OverlayType | undefined,
  (state?: OverlayType["state"], message?: OverlayType["message"]) => void,
  () => void
];

const useOverlay = (): OverlayReturn => {
  const [overlayState, setOverlay] = useState<OverlayType>();

  const changeOverlay = (
    state: OverlayType["state"],
    message: OverlayType["message"]
  ) => {
    setOverlay({
      state,
      message,
    });
  };
  const closeOverlay = () => {
    setOverlay({});
  };

  return [overlayState, changeOverlay, closeOverlay];
};

export default useOverlay;
