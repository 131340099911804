export interface IOverlay {
  state?: "loading" | "success" | "fail";
  message?: string;
  onClose?: () => void;
}

const Overlay = ({ state, message, onClose }: IOverlay) => {
  return (
    <div
      className={`${
        state ? "absolute" : "hidden"
      } w-full h-screen bg-[#000000af] z-50 flex items-center justify-center top-0`}
      onClick={() => (onClose ? onClose() : undefined)}
    >
      {state === "loading" ? (
        <img src="/loading.gif" className="w-14" alt="logo kongztech" />
      ) : null}

      {state === "success" ? (
        <div className="flex flex-col items-center">
          <img src="/success.png" className="w-14" alt="logo kongztech" />
          <h2 className="text-white text-xl font-bold mt-5">{message}</h2>
        </div>
      ) : null}

      {state === "fail" ? (
        <div className="flex flex-col items-center">
          <img src="/fail.png" className="w-14" alt="logo kongztech" />
          <h2 className="text-white text-xl font-bold mt-5">{message}</h2>
        </div>
      ) : null}
    </div>
  );
};

export default Overlay;
