const Main = () => {
  return (
    <div className="flex flex-col items-center justify-center h-[calc(100%-15rem)] m-4">
      <img src="/kongz-tech.png" alt="logo kongztech" />
      <img src="/landingpage.gif" className="w-[30rem]" alt="logo kongztech" />
    </div>
  );
};

export default Main;
