import { Routes, Route } from "react-router-dom";
import Main from "./Main";
import Revoke from "./Revoke";
import Verify from "./Verify";
import Navbar from "../components/Navbar";

import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";
import { configureChains, createConfig, WagmiConfig } from "wagmi";

import { arbitrum, mainnet, polygon, goerli } from "wagmi/chains";
import Twitter from "./Twitter";
import Footer from "../components/Footer/Footer.component";

const projectId = "d8fc883c5631cb79efa7561b9d3a9be9";

function App() {
  const chains = [goerli, arbitrum, mainnet, polygon];

  const { publicClient } = configureChains(chains, [
    w3mProvider({ projectId }),
  ]);

  const wagmiConfig = createConfig({
    autoConnect: true,
    connectors: w3mConnectors({ projectId, chains }),
    publicClient,
  });

  const ethereumClient = new EthereumClient(wagmiConfig, chains);

  return (
    <div
      className="h-screen bg-no-repeat bg-cover"
      style={{ backgroundImage: "url(/bg.png)" }}
    >
      <WagmiConfig config={wagmiConfig}>
        <Navbar />

        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="revoke" element={<Revoke />} />
          <Route path="verify" element={<Verify />} />
          <Route path="twitter" element={<Twitter />} />
        </Routes>

        <Footer />
      </WagmiConfig>
      <Web3Modal
        projectId="d8fc883c5631cb79efa7561b9d3a9be9"
        ethereumClient={ethereumClient}
      />
    </div>
  );
}

export default App;
