import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { postTwitterVerify } from "../../services/twitter";

const Twitter = () => {
  const [urlParams] = useSearchParams();
  const [errMsg, setErrMsg] = useState("");

  useEffect(() => {
    const verificationProcess = async () => {
      const oauth_token = urlParams.get("oauth_token");
      const oauth_verifier = urlParams.get("oauth_verifier");
      try {
        await postTwitterVerify(oauth_token, oauth_verifier);
      } catch (err) {
        if (typeof err === "string") {
          setErrMsg(err);
        } else {
          setErrMsg("General Error");
        }
      }
    };
    verificationProcess();
  }, [urlParams]);

  return (
    <>
      {errMsg !== "" ? (
        <div className="flex flex-col items-center justify-center h-[75%] md:h-[70%] px-6 max-w-[50rem] m-auto">
          <img
            src="/fail.png"
            className="w-14 md:w-16 xl:w-20"
            alt="logo kongztech"
          />
          <h1 className="text-white uppercase font-bold text-xl md:text-2xl xl:text-3xl m-4 text-center w-auto h-auto">
            {errMsg}
          </h1>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center h-[75%] md:h-[70%] px-6 max-w-[50rem] m-auto">
          <img
            src="/thankyou.gif"
            className="w-96 m-4 md:w-[28rem] xl:w-[36rem]"
            alt="thank you"
          />
          <h1 className="text-white uppercase font-bold md:text-lg xl:text-xl m-2 text-center w-auto h-auto">
            Your Twitter account has been verified, head back to the discord
            channel to continue.
          </h1>
        </div>
      )}
    </>
  );
};

export default Twitter;
