import React from "react";
import { useWeb3Modal } from "@web3modal/react";

const OnBoarding = () => {
  const { open } = useWeb3Modal();

  return (
    <div className="flex flex-col items-center justify-center h-4/5">
      <img className="h-32" src="/wallet.gif" alt="logo kongztech" />
      <h1 className="text-xl font-bold text-white mt-2">CONNECT YOUR WALLET</h1>

      <button className="w-28 h-10 mt-2" onClick={() => open()}>
        <img
          className="w-full h-full"
          src="/cnt_btn.png"
          alt="connect button"
        />
      </button>
    </div>
  );
};

export default OnBoarding;
