import { useEffect, useState } from "react";
import { useAccount, useSignMessage } from "wagmi";

import OnBoarding from "../../components/OnBoarding";
import NoParam from "../../components/NoParam";
import Overlay from "../../components/Overlay";

import useOverlay from "../../utils/hooks/useOverlay";
import { getGuildData, postVerifyNFT } from "../../services/verify";
import { useSearchParams } from "react-router-dom";

const Verify = () => {
  const [overlayState, changeOverlay, closeOverlay] = useOverlay();
  const [urlParams] = useSearchParams();
  const { isConnected, address } = useAccount();
  const { signMessageAsync } = useSignMessage();
  const [userData, setUserData] = useState({
    guildIcon: "/logo_mobile.png",
    guildName: "UNKNOWN",
    memberName: "UNKNOWN",
  });

  const isNoParam = () => {
    const unique_id = urlParams.get("unique_id");
    return !unique_id;
  };

  useEffect(() => {
    const unique_id = urlParams.get("unique_id");

    if (!unique_id === false) {
      getGuildData(unique_id as string).then((resp) => {
        setUserData({
          guildIcon: resp.data.icon || "/logo_mobile.png",
          guildName: resp.data.guild || "UNKNOWN",
          memberName: resp.data.member || "UNKNOWN",
        });
      });
    }
  }, [urlParams]);

  const verifyBtnHandler = async () => {
    const unique_id = urlParams.get("unique_id");

    const SIGN_MSG = `My unique ID is ${unique_id}`;

    try {
      changeOverlay("loading");
      const signResult = await signMessageAsync({ message: SIGN_MSG });
      const resp = await postVerifyNFT({
        unique_id,
        account_address: address,
        signature: signResult,
        message: SIGN_MSG,
      });

      changeOverlay("success", resp.data?.message);
    } catch (err) {
      if (typeof err === "string") {
        changeOverlay("fail", err);
      } else {
        changeOverlay("fail", "Error");
      }
    }
  };

  if (!isConnected) return <OnBoarding />;

  if (isNoParam()) return <NoParam page="verify" />;

  return (
    <div className="flex flex-col items-center justify-center h-[75%] md:h-[70%] px-6 max-w-[50rem] m-auto">
      <Overlay {...overlayState} onClose={closeOverlay} />
      <div className="flex flex-col items-center justify-center h-[150px] w-[200px] xl:h-[200px] xl:w-[250px]">
        <img
          className="flex flex-col items-center justify-center rounded-full"
          src={userData.guildIcon}
          alt="guild icon"
        />
      </div>
      <h1 className="text-white uppercase font-bold text-xl w-auto text-center md:text-3xl xl:text-4xl">
        welcome to <span className="text-[#00DFE5]">{userData.guildName}</span>{" "}
        discord server {userData.memberName}!
      </h1>
      <p className="text-white mt-5 font-semibold w-auto text-center md:text-lg xl:text-xl">
        If you have our Asset, please click the verification button below to
        unlock exclusive channels within the {userData.guildName} Discord!
      </p>
      <button
        className="w-28 h-10 m-10 xl:h-12 xl:w-36"
        onClick={verifyBtnHandler}
      >
        <img
          className="w-full h-full"
          src="/verify_btn.png"
          alt="connect button"
        />
      </button>
      <div className="bg-white h-px w-full" />
      <p className="text-white mt-6 w-auto text-center md:text-lg xl:text-xl">
        If it is your first time joining us please take a moment to read up the
        rules and official links.
      </p>
      <p className="text-white mt-4 font-semibold w-auto text-center md:text-lg xl:text-xl">
        If you’re joining us again, welcome back!
      </p>
    </div>
  );
};

export default Verify;
