import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { parseGwei } from "viem";
import {
  useAccount,
  useContractWrite,
  usePrepareContractWrite,
  useContractRead,
  useFeeData,
  useWaitForTransaction,
} from "wagmi";

import OnBoarding from "../../components/OnBoarding";
import NoParam from "../../components/NoParam";
import Overlay from "../../components/Overlay";

import { CONTRACT_ABI } from "../../utils/constants";
import useOverlay from "../../utils/hooks/useOverlay";

const Revoke = () => {
  const [urlParams] = useSearchParams();
  const contract = urlParams.get("contract");
  const operator = urlParams.get("operator");
  const target = urlParams.get("target");

  const { address, isConnected } = useAccount();
  const [overlayState, changeOverlay, closeOverlay] = useOverlay();

  const contract_read = useContractRead({
    address: contract as `0x${string}` | undefined,
    abi: CONTRACT_ABI,
    functionName: "name",
  });
  const contract_name = contract_read.data as string | undefined;

  const feeData = useFeeData({
    formatUnits: "gwei",
  });

  const { config } = usePrepareContractWrite({
    address: contract as `0x${string}` | undefined,
    abi: CONTRACT_ABI,
    functionName: "setApprovalForAll",
    args: [operator, false],
  });

  const {
    write,
    isError: isErrorContract,
    data,
    isLoading: isLoadingContract,
  } = useContractWrite(config);

  const {
    isLoading: isLoadingTx,
    isSuccess: isSuccessTx,
    isError: isErrorTx,
  } = useWaitForTransaction({
    hash: data?.hash,
  });

  useEffect(() => {
    if (isLoadingTx || isLoadingContract) {
      changeOverlay("loading");
    }
    if (isSuccessTx) {
      changeOverlay("success", "Transaction Sent!");
    }
    if (isErrorContract) {
      changeOverlay("fail", "Transaction Error!");
    }
    if (isErrorTx) {
      changeOverlay("fail", "Transaction Failed!");
    }
    // Need to disable eslint because of unintended behavior (Infinite re-render)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isErrorTx, isLoadingTx, isLoadingContract, isErrorContract, isSuccessTx]);

  const isWrongTarget = () => {
    if (target) {
      return !((address as string) === (target as string));
    }
  };

  const isNoParam = () => {
    return !contract || !operator || !target;
  };

  if (!isConnected) return <OnBoarding />;

  if (isNoParam()) return <NoParam page="revoke" />;

  return (
    <div className="flex flex-col items-center justify-center h-[75%] md:h-[70%] px-6 max-w-[50rem] m-auto">
      <Overlay {...overlayState} onClose={closeOverlay} />

      <h1 className="text-white uppercase font-bold text-3xl w-auto m-2 text-center md:text-4xl">
        Revoke <span className="text-[#00DFE5]">{contract_name}</span> approval
      </h1>
      <p className="text-white mt-8 w-auto text-center text-center font-semibold md:text-lg xl:text-xl">
        Your asset <span className="text-[#00DFE5]">{contract_name}</span> has
        been approved to an unverified{" "}
        <span className="text-[#00DFE5]">{operator}</span>.
      </p>
      <p className="text-white mt-8 w-auto text-center font-semibold md:text-lg xl:text-xl">
        Click Revoke to <span className="text-[#45F804]">revoke</span> the
        address access!
      </p>
      {isWrongTarget() ? (
        <p className="text-red-300 text-xl uppercase mt-4 w-auto text-center md:text-lg xl:text-xl">
          change your account to the address{" "}
          <span className="text-red-400">{target}</span>!
        </p>
      ) : (
        <></>
      )}

      <button
        className="w-28 h-10 mt-5 xl:w-32 xl:h-12"
        disabled={isWrongTarget()}
        onClick={() => {
          if (write) write();
        }}
      >
        <img
          className={
            isWrongTarget() ? "w-full h-full grayscale" : "w-full h-full"
          }
          src="/revoke_btn.png"
          alt="connect button"
        />
      </button>
    </div>
  );
};

export default Revoke;
