import React from "react";

const NoParam = ({ page = "verify" }) => {
  const renderText = () => {
    const RENDERED_TEXT: Record<string, JSX.Element> = {
      revoke: (
        <h1 className="text-2xl font-bold text-white mt-2 w-auto text-center">
          <span className="text-sky-300">SECURITY</span> HAS ALWAYS BEEN OUR
          <span className="text-sky-300"> HIGHEST PRIORITY</span>. PROTECT YOUR
          HOLDER PASSIVELY WITH OUR
          <span className="text-sky-300"> PROTECTION SOLUTION.</span>
        </h1>
      ),
      verify: (
        <h1 className="text-2xl font-bold text-white mt-2 w-auto text-center">
          ENHANCE YOUR HOLDERS EXPERIENCE WITH OUR
          <span className="text-sky-300"> TOKEN GATING</span> SOLUTION!
        </h1>
      ),
    };
    return RENDERED_TEXT[page];
  };

  return (
    <div className="flex flex-col items-center justify-center h-4/5">
      <div className="flex flex-col items-center justify-center m-2 w-[320px]">
        {renderText()}
      </div>
      <div className="flex m-4 gap-7 w-[320px] h-auto">
        <div className="w-auto h-auto">
          <img
            className="w-full h-full"
            src="/laptop.png"
            alt="connect button"
          />
        </div>
        <div className="w-auto h-auto">
          <img
            className="w-full h-full"
            src="/laptop.png"
            alt="connect button"
          />
        </div>
      </div>
    </div>
  );
};

export default NoParam;
